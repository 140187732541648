import React, { Fragment } from 'react';
import RichText from '../RichText';
import { Form } from '../Form';
import { BoxPlot } from '../BoxPlot';
import Link from '../Link';
import { useCookie } from 'react-use';
import { Button } from '../Button';

const sanitizeValue = (value) => {
  return value.replace(/\s+/g, '-').toLowerCase();
};


const WfeHrMetricDetailBody = (props) => {
  const {
    year,
    title,
    infographic,
    bodyText,
    faq,
    aboutContent,
    form,
    wfeHrMetrics,
  } = props;
  const RedirectURL = () => {
    updateCookie(COOKIE_VALUE_UNGATED);
    if (typeof window !== 'undefined') {
      window.location = '/saratoga-workforce-index-2022-ty';
    }
    return null;
  }
  const COOKIE_NAME = 'PWC_WFE_USER_GATE';
  // const COOKIE_VALUE_GATED = 0;
  const COOKIE_VALUE_UNGATED = '1';
  
  const [value, updateCookie, deleteCookie] = useCookie(COOKIE_NAME);
  const metrics = wfeHrMetrics.edges.map((n) => n.node);
  const sortedMetrics = metrics.slice().sort((a, b) => a.title.localeCompare(b.title));

  const NationalAverage = (props) => {
    const {
      year,
      title,
      value,
      valuePrefix,
      valueSuffix,
      industryVariance,
      revenueVariance,
      headcountVariance,
    } = props;

    const industryVarianceSlug = sanitizeValue(industryVariance);
    const revenueVarianceSlug = sanitizeValue(revenueVariance);
    const headcountVarianceSlug = sanitizeValue(headcountVariance);
    
    const Statement = (props) => {
      function makeString(arr) {
        if (arr.length === 1) return arr[0];
        const firsts = arr.slice(0, arr.length - 1);
        const last = arr[arr.length - 1];
        return firsts.join(', ') + ' and ' + last;
      }

      function jsxJoin(array, str) {
        return (
            array.map((line, index) => (
                <Fragment key={index}>
                  {index === array.length - 1 && 'and '}
                  {line}
                  {index !== array.length - 1 && ', '}
                </Fragment>
            ))
        );
      }

      let arr = [];

      if (industryVariance !== 'N/A') {
        arr.push(<Fragment>This value had <span><span className={'hr-metric-variance hr-metric-variance--' + industryVarianceSlug}>{industryVariance}</span> variance across industries</span></Fragment>);
      }

      if (revenueVariance !== 'N/A') {
        arr.push(<span><span className={'hr-metric-variance hr-metric-variance--' + revenueVarianceSlug}>{revenueVariance}</span> variance related to the revenue</span>);
      }

      if (headcountVariance !== 'N/A') {
        arr.push(<span><span className={'hr-metric-variance hr-metric-variance--' + headcountVarianceSlug}>{headcountVariance}</span> variance based on the number of employees.</span>);
      }

      return jsxJoin(arr);
    };

    const valueSuffixFormatted = ( ( valueSuffix === '$' || valueSuffix === '%' ) ? valueSuffix : ( valueSuffix ? ' ' + valueSuffix : '' ) );

    return (
        <div className="rich-text">
          {/* <h4>National Average</h4>
          <p>The national average {title} in {year} was {valuePrefix ?? ''}<strong>{value}</strong>{valueSuffixFormatted}. <Statement {...props}/> For more information on how these are calculated, download the <Link href={'#form'}>Saratoga Workforce Index</Link> eBook.</p> */}
        </div>
    );
  };

  const noFaq = faq === null;
  console.log(noFaq);

  return (
    <Fragment>
      <div className="hr-metric-detail__body">
        <div className={`hr-metric-detail__content hr-metric-detail__content--restricted`}>
          <div className="hr-metric-detail__content-inner">
            <BoxPlot year={year} title={title} {...infographic} />
            <NationalAverage year={year} title={title} {...infographic} />
            <div className="hr-metric-detail__definition rich-text">
              {noFaq && (
                <RichText source={bodyText?.json} />
              )}
              <RichText source={faq?.json} />
            </div>
            <div className="hr-metric-detail__ctablock_container">
              <h4>About the Saratoga Workforce Index</h4>
              <p>The Saratoga Workforce Index is an indispensable reference of national averages of 30 commonly requested HR metrics. Based on PwC Saratoga’s annual workforce benchmarking study of over 1000 metrics spanning hundreds of companies and over 20 industries, the Saratoga Workforce Index is your “quick start” guide to workforce benchmarks.</p>
              <h5>Download the guide to see the national averages for all of these:</h5>
              <div className="ebook_container">
                <div className="metrics_list">
                  <ul className="metrics">
                    {sortedMetrics.map((wfeHrMetric, index) => {
                      const {
                        slug,
                        title,
                      } = wfeHrMetric;
                      return (
                        <li className="metric-item"><span>{title}</span></li>
                      );
                    })}
                  </ul>
                </div>
                <div className="ebook_button">
                  <Button href={'https://www.pwc.com/us/saratoga-workforce-index'} target="_blank">Download the eBook today</Button>
                </div>
              </div>
            </div>
            <RichText source={aboutContent.content?.json}/>
          </div>
        </div>
      </div>

      <aside className="hr-metric-detail__sidebar hr-metric-sidebar">
        <div className="hr-metric-sidebar__item">
          {/* <Form onSubmitSuccess={() => RedirectURL()} {...form} /> */}
          <div>
          <p className="form__title">Download the complimentary Saratoga Workforce Index eBook</p>
          <p className="form__subtitle">Get the complete eBook of commonly requested HR metrics today.</p>
          <Button className="button button--dark button--header button--enabled header__button" href={'https://www.pwc.com/us/saratoga-workforce-index'} target="_blank">Download</Button>
          </div>
        </div>
      </aside>
    </Fragment>
  );
};

const WfeHrMetricDetail = (props) => {
  const { form } = props;

  return (
      <div className="hr-metric-detail">
        <div className="hr-metric-detail__inner">
          <WfeHrMetricDetailBody form={form} {...props} />
        </div>
      </div>
  );
};

export default WfeHrMetricDetail;
