import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import WfeHrMetricDetail from '../components/WfeHrMetricDetail';
import SiteMeta from '../components/SiteMeta';
import MediaHeroWfeHrMetric from '../components/MediaHeroWfeHrMetric';

const WfeHrMetricPage = ({ data }) => {
  const {
    allContentfulWfeHrMetric: wfeHrMetrics,
    contentfulWfeHrMetric: {
      form,
      metaTitle,
      metaDescription: {
        metaDescription,
      },
    },

  } = data;

  return (
      <Layout>
        <SiteMeta description={metaDescription} title={metaTitle}/>
        <MediaHeroWfeHrMetric {...data.contentfulWfeHrMetric} />
        <WfeHrMetricDetail {...data.contentfulWfeHrMetric} form={form} wfeHrMetrics={wfeHrMetrics}/>
      </Layout>
  );
};

export default WfeHrMetricPage;

export const query = graphql`
  query WfeHrMetricPageQuery($contentful_id : String!) {
    contentfulWfeHrMetric(contentful_id : { eq: $contentful_id  }){
      ...WfeHrMetricDetail
      form {
        ...Form
      }
      infographic {
        ...BoxPlot
      }
      aboutContent {
        ...GlobalContent
      }
    }
    allContentfulWfeHrMetric {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`;
