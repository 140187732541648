import React, { Fragment } from 'react';

const sanitizeValue = (value) => ( value.replace(/[\W_]+/g, '-').toLowerCase() );

const textWithLineBreaks = (text) => {
  if (text && text.includes('/')) {
    // Split the text into an array based on the "/" character
    const lines = text.split('/');

    text = lines.map((line, index) => (
        <Fragment key={index}>
          {line}
          {index !== lines.length - 1 && <>/<br/></>}
        </Fragment>
    ));
  }

  return text;
};

const BoxPlot = (props) => {
  const {
    year,
    title,
    value,
    valuePrefix,
    valueSuffix,
    industryVariance,
    revenueVariance,
    headcountVariance,
  } = props;

  const industryVarianceSlug = sanitizeValue(industryVariance);
  const revenueVarianceSlug = sanitizeValue(revenueVariance);
  const headcountVarianceSlug = sanitizeValue(headcountVariance);

  const calculateFontSize = (text) => {
    const textLength = text.length;
    let value = `${220}%`;

    if (textLength > 1)
      value = `${150 - ( text.length * 0.25 )}%`;
    if (textLength > 10)
      value = `${120 - ( text.length * 0.25 )}%`;

    return value;
  };

  return (
      <div className="boxplot">
        {( title || year ) &&
            <header className="boxplot__header boxplot-header">
              {title && <h3 className="boxplot__title">{title}</h3>}
              {year && <h4 className="boxplot__subtitle">{year} National Average Value</h4>}
            </header>
        }
        <div className="boxplot__variances boxplot-variances">
          <div className="boxplot__value boxplot-value">
            <p className="boxplot-value__prefix" style={{ fontSize: valuePrefix && calculateFontSize(valuePrefix) }}>{valuePrefix}</p>
            <p className="boxplot-value__value">{value}</p>
            <p className="boxplot-value__suffix" style={{ fontSize: valueSuffix && calculateFontSize(valueSuffix) }}>{textWithLineBreaks(valueSuffix)}</p>
          </div>
          <div className="boxplot-variances__vertical-bar"/>
          <div className="boxplot-variance boxplot-variance--industry">
            <div className="boxplot-variance__label">Value has</div>
            <div className={'boxplot-variance__value boxplot-variance__value--' + industryVarianceSlug}>
              <span>{industryVariance}</span>
            </div>
            <div className="boxplot-variance__definition">variance across industries</div>
          </div>
          <div className="boxplot-variance boxplot-variance--revenue">
            <div className="boxplot-variance__label">Value has</div>
            <div className={'boxplot-variance__value boxplot-variance__value--' + revenueVarianceSlug}>
              <span>{revenueVariance}</span>
            </div>
            <div className="boxplot-variance__definition">variance relative to revenue</div>
          </div>
          <div className="boxplot-variance boxplot-variance--headcount">
            <div className="boxplot-variance__label">Value has</div>
            <div className={'boxplot-variance__value boxplot-variance__value--' + headcountVarianceSlug}>
              <span>{headcountVariance}</span>
            </div>
            <div className="boxplot-variance__definition">variance relative to headcount</div>
          </div>
        </div>
      </div>
  );
};

export { BoxPlot };
